var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view view--elitepage" }, [
    _vm.bodySectionsAsMap.header
      ? _c(
          "header",
          {
            staticClass: "header",
            style: {
              backgroundColor: _vm.elitepage.header_bg_color,
              order: _vm.bodySectionsAsMap.header,
            },
          },
          [
            _vm.elitepage.header_image_url
              ? _c("img", {
                  staticClass: "section__bgOverlay",
                  style: { opacity: _vm.elitepage.header_dim_amount / 100 },
                  attrs: { src: _vm.elitepage.header_image_url },
                })
              : _vm._e(),
            _c("div", { staticClass: "container h-textCenter" }, [
              _c("h1", { staticClass: "h1 h1--eliteTitles" }, [
                _vm._v(_vm._s(_vm.elitepage.header_text)),
              ]),
            ]),
            _vm.elitepage.show_analytics
              ? _c("div", { staticClass: "analytics" }, [
                  _c(
                    "div",
                    { staticClass: "analytics__item" },
                    [
                      _c("icon", { attrs: { name: "chart-graph" } }),
                      _vm._v("Total Views: " + _vm._s(_vm.analytics.pageViews)),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "analytics__item" },
                    [
                      _c("icon", { attrs: { name: "play-full" } }),
                      _vm._v("Total Plays: " + _vm._s(_vm.analytics.beatPlays)),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _c(
      "section",
      { staticClass: "section", style: { order: _vm.bodySectionsAsMap.beats } },
      [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("PBeatTable", {
              attrs: { beatPlaylist: _vm.beats },
              on: {
                fetchMoreData: function ($event) {
                  return _vm.updateBeatsTable($event, _vm.beats.length)
                },
              },
            }),
            _c(
              "router-link",
              {
                staticClass: "btn btn--primary",
                attrs: { to: { name: "Beats" } },
              },
              [_vm._v("Load More")]
            ),
            _vm.elitepage.has_beats_in_vault
              ? _c(
                  "router-link",
                  {
                    staticClass: "btn btn--secondary",
                    attrs: { to: { name: "BeatsPrivateVault" } },
                  },
                  [_vm._v("Access My Beat Vault")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _c(
      "section",
      {
        staticClass: "section",
        style: {
          backgroundColor: _vm.elitepage.licensing_info_bg_color,
          order: _vm.bodySectionsAsMap.licensing_information,
        },
        attrs: { id: "licensingInfo" },
      },
      [
        _c("div", { staticClass: "container" }, [
          _c(
            "h2",
            {
              staticClass: "h2",
              style: { color: _vm.elitepage.licensing_info_text_color },
            },
            [_vm._v("Licensing Information")]
          ),
          _vm.sameDealForAllLicenses
            ? _c(
                "h3",
                {
                  staticClass: "h2",
                  style: { color: _vm.elitepage.licensing_info_text_color },
                },
                [
                  _vm._v("Deals:"),
                  _c("icon", {
                    staticStyle: { margin: "0 8px" },
                    attrs: { name: "deal" },
                  }),
                  _vm._v(
                    "Buy " +
                      _vm._s(_vm.sameDealForAllLicenses.dealBuy) +
                      " Get " +
                      _vm._s(_vm.sameDealForAllLicenses.dealGet) +
                      " Free"
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "licenseOptionsWrapper h-mt-30" },
            _vm._l(_vm.licenseOptions, function (license) {
              return _c("PLicenseOptionBox", {
                key: license.id,
                attrs: { license: license },
              })
            }),
            1
          ),
        ]),
      ]
    ),
    _vm.bodySectionsAsMap.sound_kits
      ? _c(
          "section",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.soundkits.length > 0,
                expression: "soundkits.length > 0",
              },
            ],
            staticClass: "section",
            style: {
              backgroundColor: _vm.elitepage.soundkit_bg_color,
              order: _vm.bodySectionsAsMap.sound_kits,
            },
          },
          [
            _vm.elitepage.soundkit_image_url
              ? _c("img", {
                  staticClass: "section__bgOverlay",
                  style: { opacity: _vm.elitepage.soundkit_dim_amount / 100 },
                  attrs: { src: _vm.elitepage.soundkit_image_url },
                })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("h2", { staticClass: "h2" }, [_vm._v("Sound Kits")]),
                _c(
                  "div",
                  {
                    staticClass: "flexbox flexbox--center flexWrap h-margin-30",
                  },
                  _vm._l(_vm.soundkits, function (soundkit) {
                    return _c("PSoundkitBox", {
                      key: soundkit.id,
                      attrs: { soundkit: soundkit },
                    })
                  }),
                  1
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn--primary",
                    attrs: { to: { name: "SoundKits" } },
                  },
                  [_vm._v("View more")]
                ),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm.bodySectionsAsMap.services
      ? _c(
          "section",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.services.length > 0,
                expression: "services.length > 0",
              },
            ],
            staticClass: "section",
            style: {
              backgroundColor: _vm.elitepage.service_bg_color,
              order: _vm.bodySectionsAsMap.services,
            },
          },
          [
            _vm.elitepage.service_image_url
              ? _c("img", {
                  staticClass: "section__bgOverlay",
                  style: { opacity: _vm.elitepage.service_dim_amount / 100 },
                  attrs: { src: _vm.elitepage.service_image_url },
                })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("h2", { staticClass: "h2" }, [_vm._v("Services")]),
                _c(
                  "div",
                  {
                    staticClass: "flexbox flexbox--center flexWrap h-margin-30",
                  },
                  _vm._l(_vm.services, function (service) {
                    return _c("PServiceBox", {
                      key: service.id,
                      attrs: { service: service },
                    })
                  }),
                  1
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn--primary",
                    attrs: { to: { name: "Services" } },
                  },
                  [_vm._v("View more")]
                ),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm.bodySectionsAsMap.youtube && _vm.elitepage.youtube_video_id
      ? _c(
          "section",
          {
            staticClass: "section",
            style: {
              backgroundColor: _vm.elitepage.youtube_bg_color,
              order: _vm.bodySectionsAsMap.youtube,
            },
          },
          [
            _vm.elitepage.youtube_image_url
              ? _c("img", {
                  staticClass: "section__bgOverlay",
                  style: { opacity: _vm.elitepage.youtube_dim_amount / 100 },
                  attrs: { src: _vm.elitepage.youtube_image_url },
                })
              : _vm._e(),
            _c("div", { staticClass: "container" }, [
              _c("h2", { staticClass: "h2" }, [_vm._v("YouTube")]),
              _c(
                "div",
                { staticClass: "flexbox flexbox--center flexWrap h-margin-30" },
                [
                  _c("iframe", {
                    attrs: {
                      src:
                        "https://www.youtube.com/embed/" +
                        _vm.elitepage.youtube_video_id,
                      width: "560",
                      height: "315",
                      frameborder: "0",
                    },
                  }),
                ]
              ),
              _vm.elitepage.youtube_channel_id
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn--primary",
                      attrs: {
                        href:
                          "https://www.youtube.com/channel/" +
                          _vm.elitepage.youtube_channel_id,
                        target: "_blank",
                      },
                    },
                    [_vm._v("Subscribe")]
                  )
                : _vm._e(),
            ]),
          ]
        )
      : _vm._e(),
    _vm.bodySectionsAsMap.mailing_list && _vm.producer.has_mail_audience
      ? _c(
          "section",
          {
            staticClass: "section",
            style: { order: _vm.bodySectionsAsMap.mailing_list },
          },
          [
            _c(
              "div",
              { staticClass: "container container--xs h-textCenter" },
              [
                _c("h2", { staticClass: "h2 h-mb-40" }, [
                  _vm._v("Subscribe to Mailing List"),
                ]),
                _c("PMailingListSignup"),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm.bodySectionsAsMap.contact
      ? _c(
          "section",
          {
            staticClass: "section",
            style: { order: _vm.bodySectionsAsMap.contact },
            attrs: { id: "contact" },
          },
          [
            _c(
              "div",
              { staticClass: "container container--xs" },
              [
                _c("ValidationObserver", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var handleSubmit = ref.handleSubmit
                          var invalid = ref.invalid
                          return [
                            _c(
                              "form",
                              {
                                staticClass: "form",
                                attrs: { autocomplete: "off", name: "contact" },
                              },
                              [
                                _c("div", { staticClass: "form__title" }, [
                                  _c("h2", { staticClass: "h2" }, [
                                    _vm._v("Contact"),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "formGroup formGroup--col2" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "formGroup" },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "email",
                                            rules: { required: true },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.contactForm
                                                              .name,
                                                          expression:
                                                            "contactForm.name",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "formInput formInput--fullWidth",
                                                      class: {
                                                        hasError:
                                                          errors.length > 0,
                                                      },
                                                      attrs: {
                                                        name: "name",
                                                        placeholder: "Name",
                                                        type: "text",
                                                        required: "",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.contactForm.name,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.contactForm,
                                                            "name",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "p",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: errors[0],
                                                            expression:
                                                              "errors[0]",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "formInput__hint formInput__hint--error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Name is required"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "formGroup" },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "email",
                                            rules: {
                                              required: true,
                                              email: true,
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.contactForm
                                                              .email,
                                                          expression:
                                                            "contactForm.email",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "formInput formInput--fullWidth",
                                                      class: {
                                                        hasError:
                                                          errors.length > 0,
                                                      },
                                                      attrs: {
                                                        name: "email",
                                                        placeholder: "Email",
                                                        type: "email",
                                                        required: "",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.contactForm.email,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.contactForm,
                                                            "email",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "formInput__hint formInput__hint--error",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "formGroup" },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "email",
                                        rules: { required: true },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.contactForm.message,
                                                      expression:
                                                        "contactForm.message",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "formInput formInput--fullWidth",
                                                  class: {
                                                    hasError: errors.length > 0,
                                                  },
                                                  attrs: {
                                                    name: "message",
                                                    placeholder: "Message",
                                                    required: "",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.contactForm.message,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.contactForm,
                                                        "message",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: errors[0],
                                                        expression: "errors[0]",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "formInput__hint formInput__hint--error",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Message can't be empty"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "formGroup" }, [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.contactForm.error,
                                          expression: "contactForm.error",
                                        },
                                      ],
                                      staticClass:
                                        "formInput__hint formInput__hint--error formInput__hint formInput__hint--error--big",
                                    },
                                    [_vm._v(_vm._s(_vm.contactForm.error))]
                                  ),
                                ]),
                                _c("div", { staticClass: "form__submit" }, [
                                  !_vm.contactForm.messageSent
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "btn btn--fullWidth btn--secondary",
                                          attrs: { type: "submit" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return handleSubmit(
                                                _vm.sendInquiryEmail
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("SUBMIT")]
                                      )
                                    : _c(
                                        "h3",
                                        { staticClass: "h-textCenter" },
                                        [_vm._v("Inquiry submitted")]
                                      ),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1970052197
                  ),
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }