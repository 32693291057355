var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pricebox",
      class: { "pricebox--featured": _vm.license.is_featured },
      style: {
        backgroundColor: _vm.elitepage.licensing_info_table_bg_color,
        color: _vm.elitepage.licensing_info_table_text_color,
      },
    },
    [
      _vm.license.is_featured
        ? _c("icon", {
            staticClass: "pricebox__featured-icon",
            style: { fill: _vm.elitepage.licensing_info_table_bg_color },
            attrs: { name: "star" },
          })
        : _vm._e(),
      _c("div", { staticClass: "pricebox__mobile-top" }, [
        _c("div", { staticClass: "pricebox__title" }, [
          _vm._v(_vm._s(_vm.license.name)),
        ]),
        _c("div", { staticClass: "pricebox__price" }, [
          _c("span", [_vm._v("$" + _vm._s(_vm.license.price))]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "pricebox__types" },
        [
          _vm.license.mp3 ? [_vm._v("MP3")] : _vm._e(),
          _vm.license.wav ? [_vm._v(", WAV")] : _vm._e(),
          _vm.license.trackout ? [_vm._v(", Trackout")] : _vm._e(),
        ],
        2
      ),
      _c("hr", {
        staticClass: "pricebox__divider",
        style: { borderColor: _vm.elitepage.color_primary_button_bg },
      }),
      _c("div", { staticClass: "pricebox__description" }, [
        _vm.license.is_exclusive
          ? _c("div", { staticClass: "pricebox__item" }, [
              _vm._v(_vm._s("Exclusive")),
            ])
          : _c("div", { staticClass: "pricebox__item" }, [
              _vm._v(_vm._s(_vm.license.intro_tag ? "Intro Tag" : "Untagged")),
            ]),
        _c("div", { staticClass: "pricebox__item" }, [
          _vm._v("Sell " + _vm._s(_vm.saleUnitsConverted) + " copies"),
        ]),
        _vm.license.show_streams
          ? _c("div", { staticClass: "pricebox__item" }, [
              _vm._v("Stream " + _vm._s(_vm.streamUnitsConverted) + " units"),
            ])
          : _vm._e(),
        _c("div", { staticClass: "pricebox__item" }, [
          _vm._v(
            _vm._s(
              _vm.license.is_royalty_free ? "Royalty Free" : "Split Revenue"
            )
          ),
        ]),
        _c(
          "div",
          { staticClass: "pricebox__deal" },
          [
            _c("icon", { staticClass: "icon-deal", attrs: { name: "deal" } }),
            _vm.license.deal_get == 0
              ? [_vm._v("No Deals")]
              : [
                  _vm._v(
                    "Buy " +
                      _vm._s(_vm.license.deal_buy) +
                      " Get " +
                      _vm._s(_vm.license.deal_get) +
                      " Free"
                  ),
                ],
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }