var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "service",
      style: {
        backgroundColor: _vm.elitepage.service_table_bg_color,
        color: _vm.elitepage.service_table_text_color,
      },
    },
    [
      _c(
        "router-link",
        {
          staticClass: "service__topWrapper",
          attrs: { to: { name: "Service", params: { id: _vm.service.id } } },
        },
        [
          _c("icon", {
            staticClass: "service__image",
            attrs: { name: _vm.service.icon },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "service__price" }, [
        _vm._v("$" + _vm._s(_vm.service.price)),
      ]),
      _c("div", { staticClass: "service__title" }, [
        _vm._v(_vm._s(_vm.service.name)),
      ]),
      _c(
        "router-link",
        {
          staticClass: "service__button btn btn--black",
          style: {
            backgroundColor: _vm.elitepage.service_table_text_color,
            color: _vm.elitepage.service_table_bg_color,
          },
          attrs: { to: { name: "Service", params: { id: _vm.service.id } } },
        },
        [_vm._v("DETAILS")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }